import { Grid } from '@mui/material'
import React from 'react'
import Heading from '../component/common/Heading'
import { Carousel } from 'react-carousel-minimal';
import img_1 from '../resources/images/abed_1.jpg'
import img_2 from '../resources/images/abed_2.jpg'
import img_3 from '../resources/images/abed_3.jpg'
import img_6 from '../resources/images/abed_6.jpg'
import img_7 from '../resources/images/abed_7.jpg'
import img_10 from '../resources/images/abed_10.jpg'
import img_12 from '../resources/images/abed_12.jpg'
import img_13 from '../resources/images/abed_13.jpg'
import img_14 from '../resources/images/abed_14.jpg'
import img_15 from '../resources/images/abed_15.jpg'
import img_16 from '../resources/images/abed_16.jpg'
import img_18 from '../resources/images/abed_18.jpg'

  

function WhyChooseUs() {

    const data = [
        {
          image: img_1,
        },
        {
          image: img_2,
        },
        {
          image: img_3,
        },
        {
          image: img_6,
        },
        {
          image: img_7,
        },
        {
          image: img_10,
        },
        {
          image: img_12,
        },
        {
          image: img_13,
        },
        {
          image: img_14,
        },
        {
          image: img_15,
        },
        {
          image: img_16,
        },
        {
          image: img_18,
        },
      ];
    
    
      const captionStyle = {
        fontSize: '4em',
        fontWeight: 'bold',
        float:'left'
      }
      const slideNumberStyle = {
        fontSize: '20px',
        fontWeight: 'bold',
      }

  return (
    <Grid container  style={{margin:'10% 0%'}}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Heading title='Why Choose Us'/>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
         <center>
            <p style={{  margin:'5% 15%', textAlign:'center' }}>
                ``  We provide full range of dermatology ,venerology, trichology and aesthetic medical services . Our Dermatologists are all seniors with wealth of knowledge and years of experience at ALERT hospital. Furthermore  our clinic has a laboratory facility equipped with the state of the art medical equipments. Our moto is to provide quality but affordability services to our beloved customers. ``
            </p>
         </center>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
         <center>
            <div >
                <Carousel
                data={data}
                time={2000}
                width="50vw"
                height="100%"
                captionStyle={captionStyle}
                captionPosition="center"
                automatic={true}
                pauseIconColor="white"
                pauseIconSize="40px"
                slideBackgroundColor="darkgrey"
                slideImageFit="cover"
                style={{
                    width: "80vw",
                    maxHeight: "500px",
                    
                }}
                />
            </div>
            </center>
        </Grid>
       
    </Grid>
  )
}

export default WhyChooseUs