import { Grid } from '@mui/material'
import React from 'react'
import Heading from '../../common/Heading'
import { about } from '../../../data/Data'
import img from '../../../resources/images/abed_1.jpg'
import img_1 from '../../../resources/images/abed_19.jpg'

function About() {
  return (
    <Grid container spacing={2}>
      
      <Grid item xs={12} sm={12} md={12} lg={12} style={{margin:'5% 0%'}}>
       <Heading title='About US' subtitle=' ' />
      </Grid>
      
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <center>
          <img style={{width:'80%', height:'30vh', margin:'0% 5%', borderRadius:"25px", }} src={img} alt='image not found'/>
          <p style={{margin:'1%'}}>ቁ 2-  ብስራተገብሬል - ከአዶት ሕንጻ ጀርባ  በሽመክት የገበያ ማዕከል 11ኛ ፎቅ</p>
          <p style={{margin:'1%'}}>No 2 - Bisrate gabriel –SHIMEKIT builiding 11th floor [behind ADOT mall ]</p>
          <img style={{width:'80%',height:'30vh', margin:'0% 5%', borderRadius:"25px", }} src={img_1} alt='image not found'/>
          <p style={{margin:'1%'}}>ቁ 1-  ቤተል - ቀድሞ ሸዋ ዳቦ ያለበት አከባቢ ንብ ባንክ አጠገብ</p>
          <p style={{margin:'1%'}}>No 1 - Betel near NIB bank [ known previously Shoa dabo area]</p>
        
        </center>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} >
       <center style={{margin:'0% 5%'}}>
        <p style={{textAlign:'left', margin:'1% 5%', padding:'5%'}}>
          {about.snippet}
        </p>
       </center>
       <center style={{margin:'0% 5%'}}>
        <p style={{textAlign:'left', margin:'1% 5%', padding:'5%'}}>
          {about.snippet_amha}
        </p>
       </center>
      </Grid>
    </Grid>
  )
}

export default About