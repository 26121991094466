import React,{useState} from 'react'
import Hero from '../component/home/hero/Hero'
import { homeData } from '../data/Data'
import About from '../component/home/about/About'
import Contact from '../component/home/contact/Contact'
import Service from '../component/home/service/Service'
import Information from './Information'
import WhyChooseUs from './WhyChooseUs'
import FeaturedNews from './FeaturedNews'
import Tips from './Tips'

function Home() {
  const [items, setItems] = useState(homeData)
  return (
    <div>
      <Hero items={items}/>
      <Information/>
      <About/>
      <WhyChooseUs/>
      <FeaturedNews/>
      <Service/>
      <Tips/>
      <Contact/>
    </div>
  )
}

export default Home