import styled from '@emotion/styled';
import { Alert, Box, Button, Card, FormControl, InputLabel, MenuItem, Select, Snackbar, Stack, TextField, Typography } from '@mui/material';
import React,{useState, useEffect} from 'react';
import {Grid} from '@mui/material';
import { addDoc, serverTimestamp, collection } from 'firebase/firestore';
import { db , storage} from '../../firebase/firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import {  createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function AddNews(props) {

  const [file, setFile] = useState("");
  const [per, setPerc] = useState(null);

  const [news, setNews] = useState({
    title:'',
    snippet:'',
    content:'',
    type:''
  });

  const handleClear = () => {
    news.title = '';
    news.content='';
    news.snippet='';
    news.type=''
}
  
const modules = {
  toolbar: {
    container: [  
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],  
      ['bold', 'italic', 'underline','link'],  
      [
          { 'list': 'ordered' },
          { 'list': 'bullet' },
          { 'indent': "-1" },
          { 'indent': "+1" }
      ],  
      [{ 'align': [] }],  
      ['clean'],  
      [{ 'color': [] }]  
    ],

 }
  }

const [message, setMessage] = useState('');


  const handleSubmit = async(e) =>{
    e.preventDefault();
    try{
      await addDoc(collection(db, 'news_and_tips'),{
        ...news,
        timeStamp: serverTimestamp()
      });
      handleSuccess();
    }catch(error){
      console.log('error :' + error.serverResponse);
      handleError();
    }
    handleClear();
  }


  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;
  
      console.log(name);
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);
  
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setNews((prev) => ({ ...prev, img: downloadURL }));
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

   const [openSuccess, setOpenSuccess] = React.useState(false);
   const [openError, setOpenError] = React.useState(false);
  
   const handleSuccess = () => {
     setOpenSuccess(true);
   };
  
   const handleError = () => {
     setOpenError(true);
   };
  
   const handleCloseSuccess = (event, reason) => {
     if (reason === 'clickaway') {
       return;
     }
  
     setOpenSuccess(false);
   };
  
   const handleCloseError = (event, reason) => {
     if (reason === 'clickaway') {
       return;
     }
  
     setOpenError(false);
   };
    
   const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ]

  
  const [confirmPassword, setConfirmPassword] = useState();

  const handleChange = (value) =>{
    setNews({...news, content: value});
  }
 
  return (
    <Box
      sx={{
        '& .MuiTextField-root': { m: 1 }, width:'80%', mt:3,height:'100%', overflowY:'scroll', border:'1px solid #120d3f'}}
      noValidate
      autoComplete="off"
    >
     <Card sx={{p:2}}>
      <div>
        <Typography variant='h6' >Add News and Tips</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{float:'left'}} >
          <div>
            <div>
              <img
                style={{width:'15%'}}
                src={
                  file
                    ? URL.createObjectURL(file)
                    : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                }
                alt=""
              />
            </div>
            </div>
            <div >
                  <label htmlFor="file" >
                    Image: <DriveFolderUploadOutlinedIcon className="icon" />
                  </label>
                  <input
                    type="file"
                    id="file"
                    onChange={(e) => setFile(e.target.files[0])}
                    style={{ display: "none" }}
                  />
                </div>
          </Grid>
        
        <Grid item xs={12} sm={12} md={6} lg={6} sx={{float:'left'}}>
        <TextField
          label="Title"
          id="outlined-size-small"
          size="small"
          value={news.title}
          onChange={(e) => setNews({...news, title: e.target.value})}
        />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} sx={{float:'left'}}>
              <FormControl size='small'>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={news.type}
                  style={{width:'18vw', marginTop:'4%'}}
                  label="Type"
                  onChange={(e) => setNews({...news, type: e.target.value})}
                >
                  <MenuItem value={'News'}>News</MenuItem>
                  <MenuItem value={'Tips'}>Tips</MenuItem>
                </Select>
              </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{float:'left'}}>
        <textarea
          placeholder="Snippet"
          id="outlined-size-small"
          size="small"
          rows={2}
          style={{border:'1px solid grey', width:'66vw'}}
          value={news.snippet}
          onChange={(e) => setNews({...news, snippet: e.target.value})}
        />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{float:'left', width:'100%'}}>
           <center>
           <ReactQuill 
                theme="snow" 
                modules={modules}
                value={news.content}   
                formats={formats}
                onChange={handleChange}
            />
           </center>
        </Grid> 
        
        <Grid item xs={12} sm={12} md={12} lg={12} style={{float:'left'}}>
            <Button variant='contained' onClick={handleSubmit} style={{backgroundColor:'#120d3f', borderRadius:'25px'}}>Save</Button>
           
        </Grid>
        </Grid>
      </div>
      </Card>
      <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleCloseSuccess}>
      <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
         Data Added Successfully!
      </Alert>
    </Snackbar>
    <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
      <Alert onClose={handleCloseError} severity="success" sx={{ width: '100%' }}>
         {message}!
      </Alert>
    </Snackbar>
    </Box>
  )
}

export default AddNews