import React from 'react'
import { Route, Router, Routes } from 'react-router-dom'
import Footer from '../component/common/footer/Footer'
import Header from '../component/common/header/Header'
import Trial from '../Trial'
import About from './About'
import Contact from './contact/Contact'
import Department from './Department'
import FeaturedNews from './FeaturedNews'
import Home from './Home'
import Login from './login/Login'
import News from './News'
import MoreNews from './news/MoreNews'
import ViewNews from './news/ViewNews'
import Service from './Service'
import Team from './team/Team'
import WhyChooseUs from './WhyChooseUs'

function Pages() {
  return (
    <>
      <Header />
      <Routes>
          <Route exact path='/' element={<Home/>} />
          <Route exact path='/about' element={<About/>} />
          <Route exact path='/services' element={<Service/>} />
          <Route exact path='/news' element={<News/>} />
          <Route exact path='/department' element={<Department/>} />
          <Route exact path='/contact' element={<Contact/>} />
          <Route exact path='/view_news' element={<ViewNews/>} />
          <Route exact path='/detail' element={<MoreNews/>} />
          <Route exact path='/team' element={<Team/>} />
          <Route exact path='/login' element={<Login/>} />
      </Routes>
      <Footer/>
    </>
  )
}

export default Pages