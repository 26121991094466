import { Grid } from '@mui/material'
import React from 'react'
import Heading from '../component/common/Heading'
import { about } from '../data/Data'

function About() {
  return (
    <Grid container spacing={2}>
      
      <Grid item xs={12} sm={12} md={12} lg={12} style={{margin:'3% 0%'}}>
       <Heading title='About US'  />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
       <center style={{margin:'1% 5%'}}>
        <p style={{textAlign:'justify', margin:'0% 5%'}}>
          {about.snippet}
        </p>
       </center>
       <center style={{margin:'1% 5%'}}>
        <p style={{textAlign:'justify', margin:'0% 5%'}}>
          {about.snippet_amha}
        </p>
       </center>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
       <center style={{margin:'1% 5%'}}>
        <p style={{textAlign:'justify', margin:'0% 5%'}}>
          {about.description}
        </p>
       </center>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
       <center style={{margin:'1% 5%'}}>
        <p style={{textAlign:'justify', margin:'0% 5%'}}>
          {about.desc_amha}
        </p>
       </center>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
       <center style={{margin:'4% 5%'}}>
        <h3 style={{margin:'2%', fontWeight:'bold'}}>{about.detail_header}</h3>
        {
          about.details.map((detail, index)=>{
            return <div style={{textAlign:'justify', margin:'1% 5%'}}>
                     {++index}.{'  '} <b>{detail.title + ' '}</b>{detail.description}
                   </div>
          })
        }
       </center>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <h3 style={{margin:'2% 10%', fontWeight:'bold'}}>{about.detail_header_amharic}</h3>
       <center style={{margin:'4% 5%'}}>
        {
          about.description_amharics.map((detail, index)=>{
            return <div style={{textAlign:'justify', margin:'1% 5%'}}>
                    {detail}
                   </div>
          })
        }
       </center>
      </Grid>
      
    </Grid>
  )
}

export default About