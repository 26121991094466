import React, {useContext, useRef} from 'react';
import './contact.css';
import Email from '../../resources/contact/email.png';
import Phone from '../../resources/contact/phone-call.png';
import Address from '../../resources/contact/pin.png';
import Heading from '../../component/common/Heading';
import emailjs from '@emailjs/browser';

function Contact() {

  const formRef = useRef();
    const sendEmail = (e) =>{
     e.preventDefault();
     emailjs.sendForm('service_tto67fc','template_8sn2p8q',formRef.current, '0qpQQP5-9Ono3oxRt').then((result)=>{
       console.log(result.text)
     },(error)=>{
       console.log(error.text)
     });
     formRef.current[0].value = '';
     formRef.current[1].value = '';
     formRef.current[2].value = '';
     formRef.current[3].value = '';
  }

  



  return (
    <div className='c'>
        <Heading title='Contact Us' subtitle='Give us you feed back' />
        <div className='c-wrapper'>
            <div className='c-left'>
                <h1 className='c-title'>
                    Let's Discuss Your Health
                </h1>
                <div className='c-info'>
                    <div className='c-info-item'>
                       <img 
                       src={Phone} 
                       alt='not found'
                       className='c-icon'
                       />
                       +251902709999<br/>
                       +251902650909
                    </div>
                    <div className='c-info-item'>
                       <img 
                       src={Email} 
                       alt='not found'
                       className='c-icon'
                       />
                       info@abedderma.com
                    </div>
                    <div className='c-info-item'>
                       <img 
                       src={Address} 
                       alt='not found'
                       className='c-icon'
                       />
                       No 1 - Betel near NIB bank  [ known previously Shoa dabo area]<br/>
                       No 2 - Bisrate gabriel –SHIMEKIT builiding  11th floor  [behind ADOT mall ]
                    </div>
                </div>
            </div>
            <div className='c-right'>
               <form ref={formRef} onSubmit={sendEmail}>
                <input  type='text' placeholder='Name' name='user_name'/>
                <input  type='text' placeholder='Subject' name='user_subject'/>
                <input  type='email' placeholder='Email'  name='user_email'/>
                <textarea rows="5" placeholder='Message' name='message' style={{border:' 1px solid grey'}}/>
                <button style={{backgroundColor:'#120d3f'}}>Submit</button>
               </form>
            </div>
        
       </div>
    </div>
  )
}

export default Contact