import { Facebook, LinkedIn, Telegram, YouTube } from "@mui/icons-material"
import React from "react"
import { footer } from "../../../data/Data"
import "./footer.css"
import {Divider, Grid} from "@mui/material";
import Logo from '../../../resources/logo/logo.png'

const Footer = () => {
  return (
    <>
      <Grid container style={{backgroundColor:'#120d3f'}}>
      <Grid item xs={12} lg={12} sm={12} md={12}>
          <footer className='footer'>
            <div className='container'>
                  <div className='row'>
                        <div className='footer-col'>
                          <ul  style={{color:'#bbbbbb', textAlign:'justify'}}>
                                <li>
                                  <img src='./images/logo/logo.png' alt='Image not found' style={{width:'100%'}}/>
                                </li>
                          </ul>
                        </div>
                        <div className='footer-col' style={{textAlign:'center',}}>
                          <h4>Quick Links</h4>
                          <ul>
                                <li><a href="/">home</a></li>
                                <li><a href="/about">about </a></li>
                                <li><a href="/services">services</a></li>
                                <li><a href="/news">News</a></li> 
                                <li><a href="/contact">contact</a></li>
                          </ul>
                        </div>
                        <div className='footer-col'>
                          <h4>contact us</h4>
                          <ul>
                              <li>Email:</li>
                              <li style={{marginLeft:'5%'}}>info@abedderma.com</li>
                              <li>Telephone:</li> 
                              <li style={{marginLeft:'5%'}}>+251902709999</li> 
                              <li style={{marginLeft:'5%'}}>+251902650909</li>
                              <li>Address:</li>
                              <li>No 1 - Betel near NIB bank  [ known previously Shoa dabo area]</li>
                              <li>No 2 - Bisrate gabriel –SHIMEKIT builiding  11th floor  [behind ADOT mall ]</li>
                          </ul>
                        </div>
                      
                        <div className='footer-col'>
                          <h4>follow us</h4>
                          <div className='social-links'>
                              <a target="_blank" href="https://www.youtube.com/@dr.digafetsegaye2292">
                                <YouTube style={{fontSize:'30px'}}/>
                              </a>
                              <a target="_blank" href="https://www.linkedin.com/in/digafe-alembo-ba429a37/">
                                <LinkedIn style={{fontSize:'30px'}}/>
                              </a>
                              <a target="_blank" href="https://t.me/abedclinic">
                                <Telegram style={{fontSize:'30px'}}/>
                              </a>
                              <a target="_blank" href="https://www.facebook.com/Dr.Tsegaye/">
                                <Facebook style={{fontSize:'30px'}}/>
                              </a>
                          </div>
                        </div>
                  </div>
            </div>
            
            </footer> 
            <div >
            <Divider style={{width:'100%'}} />
            <div style={{display:'flex', justifyContent:'space-between', color:'white', fontSize:'14px', margin:'0% 5% 2% 5%'}}>
              <div >
              Copyright © 2023. Abed Dermatology and Venerology Speciality Clinic. All Rights Reserved.
              </div>
              <div>
                Powered By <b><a href="https://www.earlystarttech.com/" target="_blank" style={{textDecoration:'none', color:'white'}}>Early Start IT Solution</a></b>
              </div>
            </div>
            
            </div>
      </Grid>
      </Grid>
    </>
  )
}

export default Footer
