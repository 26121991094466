import { Button, Card, CardActions, CardContent, CardMedia, Divider, Grid } from '@mui/material'
import React from 'react'
import Heading from '../component/common/Heading'
import location from '../resources/images/location.png'
import experts from '../resources/images/expertise.png'
import information from '../resources/images/information.png'
import { Link } from 'react-router-dom'


function Information() {
  return (
    <Grid container  style={{margin:'10% 0%'}}>
        <Grid item xs={12} sm={6} md={4} lg={4} >
        <center>
           <Card sx={{ maxWidth: 345, margin:'5%', height:'65vh', overflowY:'scroll' }} className='card'>
               <CardMedia
                   sx={{ height: 140, width:'30%', margin:'3%' }}
                   image={information}
                   title="green iguana"
               />
               <CardContent>
                   <h3 style={{margin:'8% 0%'}}>
                       Visiting Information
                   </h3>
                   <p variant="body2" color="text.secondary" style={{textAlign:'center', margin:'4%'}}>
                   <b>Betel : </b> Monday - Sunday from 8:00 a.m. – 8:00 p.m. local time. <br/>
                   <b>Bisrate Gebriel : </b>  Monday - Saturday from 8:00 a.m. – 5:30 p.m. local time.
                   </p>
                   <p variant="body2" color="text.secondary" style={{textAlign:'center', margin:'4%'}}>
                       Separate visiting hours and rules apply in the intensive care units.
                   </p>
               </CardContent>
           </Card>
        </center>
       </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
         <center>
            <Card sx={{ maxWidth: 345, margin:'5%', height:'65vh', overflowY:'scroll' }} className='card'>
                <CardMedia
                    sx={{ height: 140, width:'30%', margin:'3%' }}
                    image={experts}
                    title="green iguana"
                />
                <CardContent>
                    <h3 style={{margin:'4% 0%'}}>
                        Our Experts
                    </h3>
                    <p variant="body2" color="text.secondary" style={{textAlign:'center', margin:'8%'}}>
                        We provide the best services with our 
                        professionally trained team.
                    </p>
                </CardContent>
                 <center>
                    <Link to='team'>
                        <Button size="small" style={{backgroundColor:'#120d3f', color:'white', padding:'2%', width:'50%'}}>Meet Our Team</Button>
                    </Link>
                 </center>
            </Card>
         </center>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
         <center>
            <Card sx={{ maxWidth: 345, margin:'5%' , height:'65vh', overflowY:'scroll'}} className='card'>
                <CardMedia
                    sx={{ height: 140, width:'30%', margin:'3%' }}
                    image={location}
                    title="green iguana"
                />
                <CardContent>
                    <h3 style={{margin:'1% 0%'}}>
                        Locations
                    </h3>
                    <p variant="body2" color="text.secondary" style={{textAlign:'center', margin:'1%'}}>
                        <b>No-1 : </b>  Bethel , on the way to Woira near Nib international bank 
                    </p>
                    <p variant="body2" color="text.secondary" style={{textAlign:'center', margin:'1%'}}>
                       <b>Tele:</b> +251902709999 or +251902650909
                    </p>
                    <p variant="body2" color="text.secondary" style={{textAlign:'center', margin:'1%'}}>
                        <b>No- 2 :</b> Bisrate Gabriel, [behind Adot mall ]@ Shimekit mall , 11th floor 
                    </p>
                    <p variant="body2" color="text.secondary" style={{textAlign:'center', margin:'1%'}}>
                    <b>Tele:</b> +251902709999 or +251902650909
                    </p>
                    <p>You can look at the Google map below for accurate location.</p>
                </CardContent>
            </Card>
         </center>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
                <center>
                    <iframe width="90%" height="500" style={{margin:'5%'}} id="gmap_canvas" src="https://maps.google.com/maps?q=Abed%20Addis%20Ababa&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                    <p style={{margin:'0% 10%', fontWeight:'bold'}}>Betel Branch</p>
                </center>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
                <center>
                    <iframe width="90%" height="500" style={{margin:'5%'}} id="gmap_canvas" src="https://maps.google.com/maps?q=%20Abed%20Bisrate%20Addis%20Ababa&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                    <p style={{margin:'0% 10%', fontWeight:'bold'}}>Bisrate Gebriel Branch</p>
                </center>
        </Grid>
    </Grid>
  )
}

export default Information