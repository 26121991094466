import React, { useState, useEffect, useContext } from "react"
import "./header.css"
import {nav} from '../../../data/Data'
import { Link, useNavigate } from "react-router-dom"
import {Box} from "@mui/material"
import { AuthContext } from "../../../context/AuthContext"
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useRef } from 'react';

const Header = () => {
  const [navList, setNavList] = useState(false)

  const {dispatch} =  useContext(AuthContext);

  const {currentUser} = useContext(AuthContext);

  const navigate =  useNavigate();
  const handleLogout = () =>{
    size && setNavList(!navList)
    console.log('Logout')
    dispatch({type:"LOGOUT"})
    navigate("/");
  }

  const windowSize = useRef([window.innerWidth, window.innerHeight]);
 const [size, setSize] = useState(false);
  useEffect(() => {
    if(windowSize.current[0] <= 764){
     setSize(true)
    }
  }, [])
  

  return (
    <>
      <header style={{height:'100%'}}>
        <div className='container flex'>
         <Link to='/'>
          <div className='logo' style={{float:'left'}}>
            <img src='./images/logo/logo.png' alt='image not found' style={{height:'70%'}}/>
          </div>
         </Link>
          <div className='nav'>
            <ul className={navList ? "small" : "flex"}>
              {nav.map((list, index) => (
                <li key={index}>
                  <Link to={list.path}  onClick={(e)=>{size && setNavList(!navList)}}>{list.text}</Link>

                </li>
              ))}
             {!currentUser && <Link to='login'>
                <li  onClick={(e)=>{size && setNavList(!navList)}}style={{margin: size && '0% 5%', color: size && 'white'}}>Login</li>
              </Link>}
             {currentUser && <div style={{marginLeft:'5%', fontWeight:'500'}}>
                <li  style={{ color: size && 'white'}} onClick={handleLogout}>Logout</li>
              </div>}
              
              
            </ul>
          </div>
          <div className='toggle'>
            <button style={{backgroundColor:'white', color:'black'}} onClick={() => setNavList(!navList)}>{navList ? <CloseIcon /> : <MenuIcon style={{fontSize:'150%'}}/>}</button>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
