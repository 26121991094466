import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCxXkTAePbc_pmxAW42Mi1yvaQVIyqM_Ms",
  authDomain: "abed-513b0.firebaseapp.com",
  projectId: "abed-513b0",
  storageBucket: "abed-513b0.appspot.com",
  messagingSenderId: "496431426821",
  appId: "1:496431426821:web:5e95a4984fdbbf16c31b6c"
};


const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth();