import { Card, CardContent, CardMedia, Grid } from '@mui/material'
import React from 'react'
import Heading from '../../common/Heading'
import { services } from '../../../data/Data'


function Service() {
  return (
    <Grid container spacing={2}>
      
      <Grid item xs={12} sm={12} md={12} lg={12}  style={{margin:'5% 0%'}}>
       <Heading title='Services (በክልኒካቸን ከሚያገኙዋቸዉ አገልግሎቶች መካከል)' subtitle={services.intro} />
      </Grid>
      {services.types.map((service)=>{
        return <Grid item xs={12} sm={12} md={6} lg={4} >
                <center>
                    <Card sx={{ maxWidth: 345, margin:'2%', height:'70vh', overflowY:'scroll' }} className='card'>
                      <CardMedia
                          sx={{ height: 340 }}
                          image={service.img}
                          title="green iguana"
                      />
                      <CardContent >
                          <h3 style={{margin:'5% 0%'}}>
                              {service.title}
                          </h3>
                          {
                            service.descriptions.map((description)=>{
                              return <p variant="body2" color="text.secondary" style={{textAlign:'left', margin:'3% 10% '}}>
                                        {description}
                                    </p>
                            })
                          }
                      </CardContent>
                    </Card>
                </center>
              </Grid>
      })}
    </Grid>
  )
}

export default Service