import Slider_1 from '../resources/images/abed_3.jpg';
import Slider_2 from '../resources/images/abed_15.jpg';
import Slider_3 from '../resources/images/abed_7.jpg';
import Slider_4 from '../resources/images/abed_10.jpg';
import Slider_5 from '../resources/images/abed_14.jpg';





import consultancy from '../resources/images/consultancy.png';
import cryosurgery from '../resources/images/cryosurgery.png';
import electro_surgery from '../resources/images/electro_surgery.png';
import iontophoresis from '../resources/images/iontophoresis.png';
import microneedling from '../resources/images/microneedling.png';
import nadyag_laser from '../resources/images/nadyag_laser.png';
import skin_biopsy from '../resources/images/skin_biopsy.png';
import uvb from '../resources/images/uvb.png';


export const nav = [
    {
      text: "home",
      path: "/",
    },
    {
      text: "about",
      path: "/about",
    },
    {
      text: "services",
      path: "/services",
    },
    {
      text: "News",
      path: "/news",
    },
    {
      text: "contact",
      path: "/contact",
    },
  ]

  export const homeData = [
    {
      id: 1,
      cover: Slider_1,
    },
    {
      id: 2,
      cover: Slider_2,
    },
    {
      id: 3,
      cover: Slider_3
      
    },
    {
      id: 4,
      cover: Slider_4,
    },
    {
      id: 5,
      cover: Slider_5,
    },
  ]


  export const about = {
    snippet:'Abed Dermatology and Venereology clinic was initially founded in 2009 GC as a medium clinic at Betel. In 2016 GC it upgraded itself to a speciality clinic. The second branch of the clinic has been launched recently and is located at Bisrate Gabriel, Shimekit building. The clinic was established by a young visionary dermato-venerlogist, Dr.Digafe Tsegaye. ',
    snippet_amha:'አቤድ የቆዳ ህክምና እና ቬኔሬዮሎጂ መካከለኛ ክሊኒክ በ2002 የተመሰረተ ሲሆን በ2008 ወደ ስፔሻሊቲ ክሊኒክ ከፍ ብሏል። ክሊኒኩ የተቋቋመው በወጣት ባለራዕይ የቆዳ ህክምና ባለሙያ ዶ/ር ድጋፌ ፀጋዬ ነው። አቤድ የቆዳ ህክምና እና ቬኔሬዮሎጂ ስፔሻሊቲ ክሊኒክ "ምርጥ የቆዳ ህክምና እና ተዛማጅ አገልግሎቶችን ለህብረተሰባችን በተሻለ ሁኔታ ተደራሽ ማድረግ" የሚለውን መነሻ ዓላማውን ለማሳካት በቅርቡ ሁለተኛውን ቅርንጫፉን በብስራተ ገብርኤል ሽመክት ሞል ከፍቷል።',
    description:'In its new branch, all services with regard to skin, hair and  nail is rendered . The laboratory is fully equipped with the state the art medical devices to pick up internal disorders with skin manifestation, to perform organ function tests before prescribing  certain drugs like systemic antifungals and  last but not least  to screen and confirm  the disease that the patient has . There are three Consultant Dermato-venerologists who are working full time . There is also a senior Dermato-surgeon who  operates  on skin tumors, nail avulsions  every Monday and Friday. There are three Clinical Nurses who assist the physicians .There are also  five laboratory technicians  in the clinic doing all arrays of  lab tests.',
    desc_amha: 'በአዲሱ ቅርንጫፍም ሁሉንም የፀጉር፣ የቆዳ እና ጥፍር ምርመራ እንሰጣለን፡፡ ላቦራቶሪው ከቆዳ ህመም ጋር የውስጥ እክሎችን ለማንሳት፣ እንደ ስልታዊ ፀረ ፈንገስ ያሉ አንዳንድ መድሃኒቶችን ከማዘዙ በፊት የአካል ብቃት ምርመራዎችን ለማድረግ እና በሽተኛው ያለበትን በሽታ ለማጣራት ዘመናዊ የሕክምና መሳሪያዎችን ሙሉ በሙሉ ታጥቋል። ከዚህም በበለጠ ሙሉ ጊዜ እየሰሩ ያሉ ሶስት አማካሪ Dermato-venerologists አሉ። ሰኞ እና አርብ በቆዳ እጢዎች፣ የጥፍር ንቅሻዎች ላይ የሚሰራ ከፍተኛ የደርማቶ-ሰርጀን ሐኪም አለ። ሀኪሞቹን የሚረዱ ሶስት ክሊኒካል ነርሶች አሉ። በክሊኒኩ ውስጥ አምስት የላብራቶሪ ቴክኒሻኖች ሁሉንም የላብራቶሪ ምርመራዎችን ያደርጋሉ።',
    detail_header:'ABED DERMATOLOGY & VENEROLOGYC CLINICS  IN ADDIS ABABA, offers  a wide range of dermatological disorders including :-',
    details: [
      { title: ' Diagnosis and treatments of disorders', description: 'of the skin, hair and  nail'},
      { title: ' Investigation and treatment', description: ' of sexually transmitted infection'},
      { title: ' Evaluation and treatment of sexual dysfunction', description: ' [ erectile dysfunction, premature ejaculation ..]'},
      { title: ' Electrosurgery for benign skin lesions', description: ' such as Dermatosis papulosa nigra, seborrhoeic keratosis, skin tags, verrucated  lesions including warts'},
      { title: ' Cryotherapy', description: ' for mollsucum contagiosum, warts, skin tags, localized cutaneous leshmaniasis, actinic keratosis, seborrhoeic keratosis …'},
      { title: ' Iontophorosis –', description: ' for excessive sweating over the palms, soles and armpits'},
      { title: ' UVB – phototherapy –', description: ' for localized vitiligo, psoriasis, treatment resistant Eczema'},
      { title: ' Nd –yag laser-', description: ' for removal of unwanted tattoo, Freckles, lentignes,nevus of ito and ota'},
      { title: ' Microneedling [Derma-pen]  –', description: ' for correction of acne scars, treatment of wrinkles, skin tightening / rejuvenation'},
      { title: ' PRP [ platlate rich plasma ] –', description: ' for treatment of hair loss / thinning, acne scars and skin rejuvenation'},
      { title: ' Biopsy –', description: ' of the skin lesion '},
      { title: ' Full range of laboratory services', description: ' including hormonal tests and tumor markers '},
    ],
    detail_header_amharic:'በክልኒካቸን ከሚያገኙዋቸዉ አገልግሎቶች መካከል ፡ -',
    description_amharics: [
    ' 1. በዘመናዊ መልኩ በተደራጀ ላቦራቶሪ በመታገዝ :-  የቆዳ ፣የፀጉር  ፣የጥፍር  ና የአባላዘር በሽታዎች ህክምና',
    ' 2. በፊት ላይ ለሚወጡ ጥቋቁር ሽፍታዎች  የኤለክትሮሰርጀሪ ህክምና',
    ' 3. የቆዳና የብልት አካባቢ  ኪንታሮት ህክምና በክራዮሰርጀሪ',
    ' 4. አላስፈላጊ ንቅሳን የማጥፋት ህከምና  በናድ ያግ ሌዘር ',
    ' 5. የብጉር ጠባሳ  ማስተካከል ህክምና  በ ማይክሮኒድሊኒንግ  ( ደርማ ፔን)',
    ' 6. ዉስን ቦታ ለተከሰተ  ለምጽ  እንዲሁም የሶሪያሲሰ  በሽታ  የዩቪፒ  ኤግዚመር  ፎቶቴራፒ  ህክምና',
    ' 7. በዕጅዎ ፣ በእገርዎ ወይንም በብብትዎ ላይ  በላብ መብዛት  ከተቸገሩ   በክሊኒካቸን  በአዮንቶ ፎሮሲሲ  ህክመና  መፍትሄ ያገኛሉ፡፡',
    ' በተጨማሪ  ስለቆዳዎ ጤና አጠባበቅ ማማከር ና ሌሎቸን አገልግሎቶቸን  አጅግ ተመጣጣኝ  በሆነ ዋጋ  ልናገልገሎ   ዝግጁ መሆናችንን ስንገልጽ  በታላቅ በደስታ ነዉ፡፡',]
  }



  export const services = {
    intro:'Fully equiped laboratory services that aids in the treatment of skin, hair, nail and sexuallytransmitted diseases (በዘመናዊ መልኩ በተደራጀ ላቦራቶሪ በመታገዝ :-  የቆዳ ፣የፀጉር  ፣የጥፍር  ና የአባላዘር በሽታዎች ህክምና)',
    types:[
      {
        title:'Electro-surgery – [ hyfrecator] (የኤለክትሮሰርጀሪ ህክምና)',
        img: electro_surgery,
        descriptions:[

          '• በፊት ላይ ለሚወጡ ጥቋቁር ሽፍታዎች  የኤለክትሮሰርጀሪ ህክምና', 
          '• It is used to eliminate  seborrhoeic keratosis, DPN, skin tags, skin or mucosal warts', 
          '• Treatement of  Seborrhoeic keratosis over the face',
          '• Treatement of  DPN over the face'

        ]
      },
      {
        title:'Cryosurgery (በክራዮሰርጀሪ)',
        img: cryosurgery,
        descriptions:[
          '• የቆዳና የብልት አካባቢ  ኪንታሮት ህክምና በክራዮሰርጀሪ', 
          '• Treatment of skin or mucosal warts', 
          '• Treatement of Cutaneous Leishmaniasis',
        ]
      },
      {
        title:'Nd Yag Laser (በናድ ያግ ሌዘር )',
        img: nadyag_laser,
        descriptions:[
          '• አላስፈላጊ ንቅሳን የማጥፋት ህከምና  በናድ ያግ ሌዘር ', 
          '• To remove unwanted tattoos, birth marks, freckles, lentignes', 
        ]
      },
      {
        title:'Microneedling (ማይክሮኒድሊኒንግ)',
        img: microneedling,
        descriptions:[
          '• የብጉር ጠባሳ  ማስተካከል ህክምና  በ ማይክሮኒድሊኒንግ  ( ደርማ ፔን)', 
          '• To correct  the  troublesome acne scars, for skin rejuvenation, widening of skin pores, stretchmarks', 
        ]
      },
      {
        title:'UVB-Excimer – phototherapy (የዩቪፒ  ኤግዚመር  ፎቶቴራፒ  ህክምና)',
        img: uvb,
        descriptions:[
          '• ዉስን ቦታ ለተከሰተ  ለምጽ  እንዲሁም የሶሪያሲሰ  በሽታ  የዩቪፒ  ኤግዚመር  ፎቶቴራፒ  ህክምና', 
          '• Most uptodate treatment for  Localized Vitiligo, psoriasis, treatment resistant Eczema …', 
        ]
      },
      {
        title:'Iontophoresis (በአዮንቶ ፎሮሲሲ  ህክመና )',
        img: iontophoresis,
        descriptions:[
          '• በዕጅዎ ፣ በእገርዎ ወይንም በብብትዎ ላይ  በላብ መብዛት  ከተቸገሩ   በክሊኒካቸን  በአዮንቶ ፎሮሲሲ  ህክመና  መፍትሄ ያገኛሉ፡፡', 
          '• Famous treatment for  the control of excessive sweating of the palms, soles and armpits ', 
        ]
      },
      {
        title:'Skin biopsy',
        img: skin_biopsy,
        descriptions:[
          '• ማንኛውንም የቆዳ ህመም ለማረጋገጥ አስተማማኝ ምርመራ.', 
          '• A gold standard test used to confirm  any skin disorder.', 
        ]
      },
      {
        title:'Medical Consultancy ',
        img: consultancy,
        descriptions:[
          '• በተጨማሪ  ስለቆዳዎ ጤና አጠባበቅ ማማከር ና ሌሎቸን አገልግሎቶቸን አጅግ ተመጣጣኝ በሆነ ዋጋ ልናገልገሎ ዝግጁ መሆናችንን ስንገልጽ  በታላቅ በደስታ ነዉ፡:',
          '• We are also pleased to announce that we are ready to provide you with skin care consultations and other services at affordable prices.', 
        ]
      },
    ],
  }


  export const team = [
    {
      cover: "../images/team/abed_5.jpg",
      position: "CEO, Entrepreneur and Founder of ABED Dermatology and Venerology Speciality Clinics",
      name: "Dr. Digafe Tsegaye",
      experience:'MD + Expert consultant Dermato-Venerologist and certified aesthetic medicine specialist. Over 15 years of experience in the field of Dermato-Venerology at ALERTT Hospital.',
      icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
    },
    {
      cover: "../images/team/abed_8.png",
      position: "Consultant Dermato Venerologist",
      name: "Dr. Simret Mesfin",
      experience:'Over 5 years of experience in the field of Dermato-Venerology. Also worked in Aesthetic set ups with good skills and knowledge on skin care and cosmetic dermatology.',
      icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
    },
    {
      cover: "../images/team/abed_9.png",
      position: "Expert Laboratory Technician",
      name: "Sofia Hasen",
      experience:'Over 13 years of experience in the field so that she has gained the expert title.',
      icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
    },
    {
      cover: "../images/team/abed_11.png",
      position: "BSC Nurse",
      name: "Bitaw Mulu",
      experience:'Over 2 years of experience in the field. Well known for his compassionate care and service for patients needing nursing service.',
      icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
    },
  ]

  export const footer = [
    {
      title: "Contact",
      text: [{ list: "Email: \nfanta.tesgera@ucs.com.et     ftesgerajetu@yahoo.com" }, { list: "Telephone: \n+251911256485 \n+251911445721" }, { list: "P.o.Box 12682, Addis Ababa, Ethiopia " }, { list: "Address: \nYeka Sub-City, Woreda 12, House No. 1027" }],
    },
  ]