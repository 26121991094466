import { Card, CardContent, CardMedia, Grid } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import React,{useEffect, useState} from 'react'
import Heading from '../component/common/Heading'
import { db } from '../firebase/firebase';

function FeaturedNews() {
    const [news, setNews] = useState([]);
    useEffect(() => {
        const q = query(collection(db, "news_and_tips"), where('type', '==','News'));
        const unsub = onSnapshot(q, (snapShot) => {
          let list = [];
          snapShot.docs.forEach(doc=>{
            list.push({id: doc.id, ...doc.data()});
          })
          list.sort((a, b) => parseFloat(a.timeStamp) - parseFloat(b.timeStamp));
          setNews(list);
      },(error)=>{
         console.log(error);
      });
      return () =>{
        unsub();
      }
      }, []);
      const navigate = useNavigate();

      const handleDetail = (row) =>{
        navigate('/detail', { state: { ...row } });
      }
  return (
    <div>
        <Grid container >
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Heading title=' News'/>
            </Grid>
        </Grid>
        <Grid container  style={{height:'100vh', overflowY:'scroll'}}>
                {
                news.map((tip)=>{
                return <Grid item xs={12} sm={6} md={6} lg={4}>
                            <center>
                                <Card sx={{ maxWidth: 345, margin:'5%', height:'60vh', overflowY:'scroll'}} className='card'>
                                    <CardMedia
                                        sx={{ height: 240 }}
                                        image={tip.img}
                                        title="green iguana"
                                    />
                                    <CardContent>
                                        <h3>
                                            {tip.title}
                                        </h3>
                                        <p variant="body2" color="text.secondary" style={{textAlign:'justify', overflowY:'scroll', height:'7vh'}}>
                                            {tip.snippet}
                                        </p>
                                    </CardContent>
                                    <center>
                                        <div style={{display:"block", margin:'5% 0%'}}>
                                        <button onClick={(e)=>{
                                            handleDetail(tip)
                                        }}>Read More</button>
                                        </div>
                                    </center>
                                </Card>
                            </center>
                        </Grid>
                })
            }
        </Grid>
    </div>
  )
}

export default FeaturedNews